import React from 'react';
import navigationServices from '../../services/navigationServices';
import { Link } from 'react-router-dom';
import PackageOverview from '../../components/landingPageSections/packagesSection/PackageOverview';
import OurPackages from '../../components/landingPageSections/packagesSection/OurPackages';

const fullText_giveBirthInArgentinaChangesIn2025: React.FC = () => {
    return (
        <div>
            <p>Какие изменения могут быть в 2025 году для родов в Аргентине?</p>
            <h3><strong>1. Платная медицина для нерезидентов Аргентины.</strong></h3>
            <p>Не так давно правительство Аргентины сообщило, что для иностранных граждан медицинское обслуживания, так же как и образование <strong>будет на платной основе</strong>. Такая система уже существует в некоторых провинциях Аргентины. В самом <strong>Буэнос-Айресе на данный момент изменений никаких не наблюдаем</strong>: наши девочки рожают в бесплатных клиниках, бесплатно ставят прививки деткам и взрослым, могут бесплатно обслуживаться в государственных клиниках и госпиталях.</p>
            <h3><strong>2. Оформление документов на ребенка.</strong></h3>
            <p>Мы стали замечать, что сейчас намного сложнее получить документы на родившегося ребёнка. Для того чтобы получить первое дни, документы родителей должны пройти верификации. Раньше она занимала 24 часа. На сегодняшний день этот процесс может занять <strong>до 25 дней</strong>, что затрудняет ускоренное получение DNI. А вот с ускоренной выдачей паспортами ребёнка проблем нет.</p>
            <h3><strong>3. Стоимость и цена родов.</strong></h3>
            <p>Что касается <strong>стоимости родов</strong>, тут цены растут не так быстро, как они росли в прошлом году - с каждым месяцем в одном из лучших госпиталей стоимость увеличивалась каждый месяц на 15-20 процентов. Но всё равно повышения наблюдаются. Госпиталь сейчас обойдется в сумму <strong>от 2500 до 3500</strong> долларов в зависимости от способа родоразрешения и, конечно, самой клиники.</p>
            <p><strong>Стоимость услуг врачей </strong>на роды в Аргентине за последний год практически не изменилась и остается в пределах <strong>от 1600 до 3000 </strong>долларов за роды в зависимости от способа родоразрешения. За прием врач у вас возьмет <strong>в среднем 25-30 долларов</strong>.</p>
            <p>А вот <strong>стоимость анализов</strong> увеличивается по сравнению с прошлым годом, так же как и стоимость УЗИ тоже, которая возросла по сравнению с прошлым годом <strong>в три</strong>, а в некоторых местах и <strong>в четыре раза</strong>.</p>
            <p>В некоторых частных клиниках еще осталась система работы по принципу «Все включено». К такой клинике относится Гуэмес, где можно рожать с дежурной бригадой врачей. Кроме этого в стоимость клиники уже включены необходимые анализы, обследования, а также приемы у врачей. По такой системе <i><strong>работала и клиника ИМО, но 7 февраля 2025, к сожалению, она прекратила работу.</strong></i></p>
            <h3><strong>4. Цены на жилье. </strong></h3>
            <p>Особого повышения стоимости аренды жилья не наблюдалось, однако <strong>значительно поднялись цены на коммунальные услуги</strong>: оплата за свет и за газ поднялась <strong>в два-три раза</strong>. Стоимость коммунальных услуг и экспенсас зависит от выбранной квартиры и обычно оговаривается при подписании договора. А стоимость <strong>аренды квартиры</strong> остается <strong>от 800 долларов</strong>. Конечно же многое зависит от района, местоположения и от ремонта.</p>
            <h3><strong>5. Цены в магазинах.</strong></h3>
            <p>Говоря о ценах на продукты в супермаркетах и разного рода лавках (овощных, мясных, хлебных и пр. ) нужно учитывать такие вещи, как сезонность, сеть магазинов, а иногда даже район (последнее относится именно к лавкам). Цены на фрукты и овощи в зависимости от климата и от времени года могут повышаться в пять-шесть раз, но «в сезон» могут падать. Цены на молочные и другие продукты имеют тенденцию к повышению. Не могу сказать, что это происходит ежемесячно, но все же цены растут постоянно. Цены в Аргентине сейчас такие же, как во всей Европе. В средне на семью с маленьким ребенком поход в супермаркет с закупкой <strong>продуктов на неделю обойдется в 100-160 долларов</strong>. Опять же надо понимать, что этот вопрос весьма индивидуален.</p>
            <h3><strong>6. Нахождение в Аргентине во время рассмотрения дел о гражданстве.</strong></h3>
            <p> Для того чтобы избежать трудностей и дополнительной бумажной работы с получением гражданства мы советуем вам находиться на территории Аргентины. Даже часовая поездка на водопады Игуасу на бразильскую сторону может привести к тому, что судья или прокурор просят дополнительные справки от вас, что может увеличить время рассмотрения дела.</p>
            <h3><strong>7. Детские вещи.</strong></h3>
            <p>Если вы планируете покупать в Буэнос-Айресе <strong>вещи для малыша</strong>, то они обойдутся <strong>в сумму около 500 долларов</strong> (не считая одежды и подгузников). Надо помнить, что Аргентина - это страна, где самая дорогая одежда. И детская одежда не является исключением. Например, боди для малыша может стоить 7-10 долларов. Пачка подгузников Pampers Premium Care для новорожденного 56 шт может стоить 20 долларов (однако, нужно помнить, это есть и более бюджетные варианты подгузников), а смесь для новорожденных стоит от 20 долларов за 800 гр. Впрочем, если вы будет кормить ребенка грудью, то за смесь вам переживать не стоит. </p>
            <h3><strong>8. Транспорт.</strong></h3>
            <p>Такси по сравнению с 2024 годом в Аргентине подорожало. Стоимость за поездку будет зависеть от расстояния и построенного маршрута. Из Палермо в Кабашито можно добраться за 8 долларов. В среднем <strong>на услуги транспорта</strong> у клиентов может выйти <strong>до 200 долларов</strong>. В Буэнос-Айресе также очень развита сеть общественного транспорта. Конечно использовать его намного выгоднее, чем такси. Стоимость проезда на автобусе в среднем 0,5 доллара. </p>
            <h3><strong>Итог. Бюджет на роды в Аргентине в 2025 году.</strong></h3>
            <p>Планируя бюджет на роды в Аргентине в 2025 году следует учитывать многие факторы. Многие вещи очень индивидуальны и зависят только от вас. В среднем
                <hr />В среднем выходит <strong>сумма около 25000 долларов в 2025 году</strong> на роды в Аргентине с учетом всех расходов (перелет, проживание, покупки в магазины, походы в рестораны и развлечения)
                <hr />. Если <strong>сократить бюджет до необходимого минимума</strong>, то <strong>можно уложиться и в 15000 долларов</strong>. Все зависит только от вас и от ваших потребностей.</p>
            <OurPackages />

        </div>


    )
};

export default fullText_giveBirthInArgentinaChangesIn2025;