export const prcValues = [
    "1500", // standard
    "2000", // premium
    "10000",
    "1000", // already here
    "0", // placeholder
    "300", // mini just birth
    "500", // mini birth before
    "500", // mini birth after
    "4000" // full package
];

export default prcValues;